/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import React from "react";
import "./src/styles/global.css";
import { webVitals } from "./web-vitals";
import { hubspotCookieValue, setCookie, userCookieValue } from "@utils/cookies";
import { queueARMABeacon } from "@utils/ARMA";
import { GlobalContextProvider } from "./src/components/GlobalContextProvider";

export const onInitialClientRender = () => {
  const scrollListener = () => {
    events.forEach(event => {
      window.removeEventListener(event, scrollListener);
    });

    // This is where we can init google tag manager ( https://www.gatsbyjs.com/blog/making-the-gatsby-homepage-more-performant/ # Removing excess dependencies and code)
    if (window.dataLayer !== undefined) {
      setTimeout(() => {
        window._mtm?.push({ event: "initial-client-render" });
        const initialClientRenderEvent = new Event("initial-client-render");
        window.dispatchEvent(initialClientRenderEvent);
      }, 200);
    }

    // retrieves the matomo visitor id and push it to MTM data layer
    const getMatomoVisitorID = () => {
      setTimeout(() => {
        window._paq?.push([
          function () {
            // @ts-expect-error 2683
            const matVisId = this.getVisitorId();
            if (matVisId) {
              window.mvid = matVisId;
              setCookie("mvid", matVisId);
            }
          },
        ]);
      }, 200);
    };

    // pushes the accuranker and hubspot user IDs to MTM data layer
    const pushCookiesToDataLayer = () => {
      const u = userCookieValue();
      const h = hubspotCookieValue();
      window._mtm = window._mtm || [];
      if (u) {
        window._mtm.push({ accUserId: u });
      }
      if (h) {
        window._mtm.push({ hubspotutk: h });
      }
    };

    const initialize = () => {
      getMatomoVisitorID();
      pushCookiesToDataLayer();
    };

    setTimeout(() => {
      "requestIdleCallback" in window
        ? requestIdleCallback(() => initialize())
        : initialize();
    }, 300);
  };

  const events = ["scroll", "mousemove", "keydown", "click", "touchstart"];
  events.forEach(event => {
    window.addEventListener(event, scrollListener);
  });

  //Load the CookieBot script in JS instead of through MTM to prevent adblockers from blocking it
  window.addEventListener(
    "initial-client-render",
    () => {
      const scriptElement = document.createElement("script");
      scriptElement.id = "cookiebot-script";
      scriptElement.innerHTML = `window.addEventListener("CookiebotOnConsentReady", function () {
        var C = Cookiebot.consent,
            c = ["preferences", "statistics", "marketing"];
        function m(a) {_mtm.push({event: "cookie_consent_" + a})}
        c.forEach(function (i) {C[i] && m(i)})
      })
    
      !function(C,oo,k,ie,b,o,t){
      b=C.createElement(oo),o=C.getElementsByTagName(oo)[0];
      b.src='https://consent.cookiebot.com/uc.js?cbid='+ie;
      b.id=k;b.async=!0;o.parentNode.insertBefore(b,o);
      }(document,'script','Cookiebot','c929fb40-8268-4405-888c-15bc500280a5')`;
      document.body.appendChild(scriptElement);
    },
    { once: true }
  );
  const fingerprintScript = document.createElement("script");
  // fingerprintScript.id = "fingerprint-script";
  fingerprintScript.innerHTML = `
    (function() {
      const getCookie = (cookie) =>
        document.cookie
          .split("; ")
          .find(row => row.startsWith(\`\${cookie}=\`))
          ?.split("=")[1];
          
      const setCookie = (cname, cvalue) => {
        if (!cname || !cvalue) return;
        const d = new Date();
        d.setTime(d.getTime() + 1000 * 24 * 60 * 60 * 1000); // 1000 days
        const expires = "expires=" + d.toUTCString();
        document.cookie = \`\${cname}=\${cvalue};\${expires};domain=accuranker.com;path=/;samesite=lax;secure\`;
      };
    
      const savedFp = getCookie("fp")
      if (savedFp) {
        window.fp = savedFp
        setCookie("fp", window.fp);
      } else {
        import('https://accuranker.com/SxXKHkbGMzWmkq8o/BhOEVDyLmu32oJK5?apiKey=IJmdR868Ho')
          .then(FingerprintJS => FingerprintJS.load({
            endpoint: [
              "https://accuranker.com/SxXKHkbGMzWmkq8o/hGkmMgGjf2YHK0o7?region=eu",
              FingerprintJS.defaultEndpoint
            ],
            region: "eu"
          }))
          .then(fp => fp.get())
          .then(result => {
            const visitorId = result.visitorId
            window.fp = visitorId
            if (window.fp) {
              setCookie("fp", window.fp);
            }
          })
      }
    })(); 
  `;
  document.body.appendChild(fingerprintScript);
};

let isFirstLoad = true;

//track pageviews
let currentUrl = window.location.href;
export const onRouteUpdate = ({
  prevLocation,
}: {
  prevLocation: Pick<Location, "pathname">; // not exact type
}) => {
  queueARMABeacon("gatsby-route", "update", prevLocation?.pathname);

  if (!isFirstLoad) {
    window.gtag("event", "page_view", {
      page_location: window.location.href,
      page_title: window.document.title,
    });
  } else {
    isFirstLoad = false;
  }
  window._mtm?.push({ event: "gatsby-route-change" });
  window._paq?.push(["setReferrerUrl", currentUrl]);
  currentUrl = window.location.pathname;
  const pageTitle = window.document.title;
  window._paq?.push(["setCustomUrl", currentUrl]);
  window._paq?.push(["setDocumentTitle", pageTitle]);
  // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
  window._paq?.push(["deleteCustomVariables", "page"]);

  window._paq?.push(["trackPageView"]);

  // custom tag manager variables
  window._mtm?.push({ fp: window.fp });
};

const trackEvent = (
  category: string,
  action: string,
  label?: string,
  value?: string | number
) => {
  queueARMABeacon("webvitals", action, value?.toString());
  window._paq?.push(["trackEvent", category, action, label, value]);
};

export const onClientEntry = (): void => {
  const options = {
    trackEvent,
    includeInDevelopment: true,
    trackWebVitals: true,
    eventCategory: "Web Vitals",
    metrics: ["LCP", "FID", "CLS", "FCP", "INP", "TTFB"],
  };

  if (!options.trackEvent || !options.trackWebVitals) {
    return;
  }

  if (options.includeInDevelopment || process.env.NODE_ENV === "production") {
    webVitals({ options });
  }
};

export const wrapPageElement = ({
  element,
  props,
}: {
  element: React.ReactNode;
  props: any;
}) => {
  return (
    <GlobalContextProvider value={props.pageContext || {}}>
      {element}
    </GlobalContextProvider>
  );
};
